<template>
  <div class="sp-container">
    <transition class="sp-content text_bold text_small text_uppercase">
      <p
        v-bind:class="{ animated: show, hided: !show }"
        @animationend="animEnd"
        ref="frame"
        class="frame"
      >
        {{ text }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  name: "uiAnimatedTexts",
  props: { texts: Array },
  data() {
    return {
      show: true,
      currentIndex: 0,
      text: "",
    };
  },
  methods: {
    animEnd() {
      this.show = false;
      this.currentIndex++;
      if (this.currentIndex > this.texts.length - 1) {
        this.currentIndex = 0;
      }
      this.text = this.texts[this.currentIndex];
      setTimeout(() => {
        this.show = true;
      }, 0);
    },
  },
  mounted() {
    this.text = this.texts[this.currentIndex];
  },
};
</script>

<style scoped>
.sp-content {
  z-index: 1000;
}
.animated {
  color: transparent;
  -webkit-animation: blurFadeInOut 5s ease-in backwards;
  -moz-animation: blurFadeInOut 5s ease-in backwards;
  -ms-animation: blurFadeInOut 5s ease-in backwards;
  animation: blurFadeInOut 5s ease-in backwards;
}
.hided {
  opacity: 0;
}

@-webkit-keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    -webkit-transform: scale(1.3);
  }
  10%,
  90% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    -webkit-transform: scale(0);
  }
}
/**/

@-moz-keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    -moz-transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -moz-transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    -moz-transform: scale(0);
  }
}

@keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    transform: scale(0);
  }
}
</style>
